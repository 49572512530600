import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/Button"
import PubSearch from "../components/PubMap/Search"

const CorporateGiftsPage = () => {

    const images = [
        {
            src: '/banners/landing-banner-corporategifts-1.jpg',
            alt: 'Wine glasses',
            link: null
        },
        {
            src: '/banners/landing-banner-corporategifts-2.jpg',
            alt: 'Dinner',
            link: null
        },
        {
            src: '/banners/landing-banner-corporategifts-3.jpg',
            alt: 'The Red Lion',
            link: null
        },
        {
            src: '/banners/landing-banner-corporategifts-4.jpg',
            alt: 'Desserts',
            link: null
        },
    ];

    return <Layout>
        <Seo
            title={`The Dining Out Gift Card | Valid in over 1600 Restaurants, Pubs & Bars`}
            description={`Valid in restaurants across the UK including Miller & Carter, Harvester, Toby Carvery & more. The perfect gift for birthdays, thank yous, rewards and gifts.`}
        />

        <ImageCarousel images={images} />

        <LogoStream />

        <div className="content-wrapper">

            <div className="main-with-sidebar">
                <h1>Corporate Gift Cards</h1>

                <h2>Corporate Gift Cards and Rewards for your Customers and Staff</h2>

                <p>Whether it's a Christmas bonus for your hard-working team, a treat for a job well done or a thank you to your customers, the Dining Out Gift Card offers something everyone will enjoy and appreciate.</p>

                <p>The Dining Out Gift cards and e-gift codes are valid at over 1600 venues throughout the UK across 16 different brands from Miller & Carter to Harvester, Toby Carvery, All Bar One and many more.</p>

                <p>Available at any value from £5 to £250, they're the perfect gift for any occasion from a 'Well done - have a beer on us', right through to higher value long time service awards.​</p>

                <p>A 24 month expiry gives your recipients plenty of time to experience exactly what they love the most: a weekend away, a romantic meal for two, a family get together or even a guys or girls night out.​</p>
                
                <Button
                    href="https://diningout-biz.cashstar.com/"
                    target={'_blank'}
                    rel={'noreferer'}
                >
                    Buy Corporate Gift Cards
                </Button>
            </div>

            <div className="sidebar">
                <div className="box">
                    <a href="https://diningout-biz.cashstar.com/" rel="noreferrer" target="_blank" className="w-full">
                        <StaticImage
                            src="../../static/boxes/corporate-gift-cards.png"
                            alt="Corporate Gifts"
                            className="w-full"
                        />
                    </a>
                </div>
            </div>
        </div>

        <PubSearch />
    </Layout >
}

export default CorporateGiftsPage
